<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 60 60" width="60" height="60">
    <g>
      <g id="Capa_1">
        <g>
          <path class="cls-1" d="M21.9,32.7h-3.7c-1.2,0-2.2,1-2.2,2.2v3.7c0,1.2,1,2.2,2.2,2.2h3.7c1.2,0,2.2-1,2.2-2.2v-3.7c0-1.2-1-2.2-2.2-2.2ZM22.8,38.6c0,.5-.4.9-.9.9h-3.7c-.5,0-.9-.4-.9-.9v-3.7c0-.5.4-.9.9-.9h3.7c.5,0,.9.4.9.9v3.7Z"/>
          <path class="cls-1" d="M59.7,26.5L29.9.8,0,26.5c-.3.2-.3.6,0,.9.1.1.3.2.5.2s.3,0,.4-.2l4.4-3.8v31.3c0,1.5,1.2,2.8,2.8,2.8h43.7c1.5,0,2.8-1.2,2.8-2.8v-31.3l4.4,3.8c.3.2.7.2.9,0,.2-.3.2-.7,0-.9ZM42.7,56.5h-10.9v-21.6c0-.5.4-.9.9-.9h9.1c.5,0,.9.4.9.9v21.6ZM53.2,55.1c0,.8-.7,1.5-1.5,1.5h-7.8v-21.6c0-1.2-1-2.2-2.2-2.2h-9.1c-1.2,0-2.2,1-2.2,2.2v21.6H8c-.8,0-1.5-.7-1.5-1.5V22.6L29.9,2.5l23.3,20.1v32.4Z"/>
        </g>
      </g>
    </g>
  </svg>
</template>


<style lang="scss" scoped>
.cls-1 {
  fill: #252525;
}
</style>
